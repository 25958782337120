<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">Master Peran</h4>
            </div>
            <span @click="addBtnHandle" class="btn btn-primary" v-if="validasi(['is_admin', 'peran_add'])">
              Tambah Baru
            </span>
          </div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :line-numbers="true"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                position: 'top',
                perPageDropdown: [5, 10, 20],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'Selanjutnya',
                prevLabel: 'Sebelumnya',
                rowsPerPageLabel: 'Jumlah baris',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <b-button size="sm" @click="editBtnHandle(props)" v-if="validasi(['is_admin', 'peran_edit'])" variant="primary" class="mr-2" title="Edit">
                    <i class="dripicons dripicons-pencil"></i>
                  </b-button >
                  <b-button size="sm" @click="showDelete(props)" v-if="validasi(['is_admin', 'peran_delete'])" variant="danger" class="mr-2" title="Hapus">
                    <i class="dripicons dripicons-trash"></i>
                  </b-button>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
          <!-- MODAL DELETE -->
          <b-modal :no-close-on-backdrop="true" id="modalDelete" title="Hapus Peran">
            <p>Apakah anda yakin untuk menghapus peran <strong>{{ lblPeran }}</strong> ?</p>
            <template #modal-footer="{ cancel }">
              <b-button variant="primary" @click="cancel()">
                Batal
              </b-button>
              <b-button variant="danger" @click="modalDelete()">
                Hapus
              </b-button>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table';
  import { getDataService, postDataService, deleteDataService } from '../../../store/modules/crudservices'
  import { cekAkses } from '../../../config/authenticate'

  export default {
    name:'PeranList',
    data () {
      return {
        modalTitle: '',
        lblPeran: '',
        columns:[
          {
            label: 'id',
            field: 'id',
            hidden: true
          }, {
            label: 'Nama Peran',
            field: 'name'
          }, {
            label: 'Aksi',
            field: 'action',
            sortable: false,
            width: '125px',
            tdClass: 'text-center',
            hidden: !this.validasi(['is_admin', 'peran_edit', 'peran_delete'])
          }
        ],
        rows: [],
        form:{
          name: null
        }
      }
    },
    methods: {
      refreshLists(){
        getDataService('/role-grid').then(data => {
          this.rows = data.data
        })
      },
      addBtnHandle() {
        this.$router.push('/config/peran/add')
      },
      editBtnHandle(props) {
        const url = `/config/peran/edit/${props.row.id}`
        this.$router.push(url)
      },
      showDelete(val){
        this.lblPeran = val.row.name
        this.form.id = val.row.id
        this.$bvModal.show('modalDelete')
      },
      modalSave(){
        const _formData = new FormData()
        _formData.append('id', this.form.id ?? 0)
        _formData.append('name', this.form.name)
        _formData.append('descr', this.form.descr)

        postDataService(_formData).then(response => {
          console.log('res', response)
          if(response.data.success)
            this.$bvModal.hide('modalPeran')
            this.refreshLists()
        })
      },
      modalDelete(){
        const url = '/role/' + this.form.id
        deleteDataService(url).then(response => {
          if(response.data.success)
            this.$bvModal.hide('modalDelete')
            this.refreshLists()
        })
      },
      validasi(perms) {
        return cekAkses(perms)
      },
    },
    components: {
      VueGoodTable,
    },
    mounted() {
      this.refreshLists()
    }
  }
</script>